<template>
  <div class="border-t border-neutral-50 first:border-transparent">
    <button
      type="button"
      class="focus:outline-none group flex w-full items-center justify-between p-5 pr-4"
      @click="toggleOpen()"
    >
      <div class="flex items-baseline">
        <span class="mr-2 text-sm font-medium text-neutral-900">
          {{ title }}
        </span>
        <span
          v-if="selectedFilters.length > 0"
          class="text-xs text-neutral-700"
        >
          {{
            $t("components.brandMarketing.assets.assetsFilter.numberSelected", {
              number: selectedFilters.length,
            })
          }}
        </span>
      </div>
      <div
        class="relative h-6 w-6 text-neutral-400 transition-all duration-200 group-hover:text-neutral-900"
      >
        <IconMinus class="absolute inset-0" />
        <IconMinus
          :class="[
            'absolute inset-0 transform transition-transform duration-200',
            { 'rotate-90': !isOpen },
          ]"
        />
      </div>
    </button>
    <div
      :class="[
        'px-5 pb-5',
        {
          hidden: !isOpen,
        },
      ]"
    >
      <div v-if="loading">
        <LoadingIndicator class="mx-auto w-6" />
      </div>
      <div v-if="!loading">
        <div
          v-for="(filter, index) in filters"
          :key="index"
          :class="[
            'relative mb-4',
            {
              hidden: index >= initialVisibleAssets && !isExpanded,
              'last:mb-0': !showMoreFiltersButton,
            },
          ]"
        >
          <input
            type="checkbox"
            class="absolute top-0 left-0 opacity-0"
            :checked="isSelected(filter)"
          />
          <label
            class="group relative flex cursor-pointer items-center text-sm text-neutral-600"
            @click="select(filter)"
          >
            <div
              class="mr-2 flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-sm border-2 border-neutral-100 bg-white transition-all duration-200 group-hover:border-neutral-300"
            >
              <IconCheckbox class="opacity-0" />
            </div>
            <div>{{ filter.name }}</div>
          </label>
        </div>
        <button
          v-if="showMoreFiltersButton"
          type="button"
          class="focus:outline-none group mt-2 inline-flex items-center text-xs font-medium"
          @click="toggleExpanded()"
        >
          <div
            class="mr-2 flex h-5 w-5 flex-shrink-0 items-center justify-center"
          >
            <IconChevronDown
              :class="{ 'rotate-180': isExpanded }"
              class="h-4 w-4 transform text-neutral-400 transition-all duration-200 group-hover:text-neutral-900"
            />
          </div>
          <span v-if="!isExpanded">
            {{
              $t("components.brandMarketing.assets.assetsFilter.button", {
                number: moreFiltersNumber,
                label: title.toLowerCase(),
              })
            }}
          </span>
          <span v-else>
            {{ $t("components.brandMarketing.assets.assetsFilter.showLess") }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Array,
      required: true,
    },
    selectedFilters: {
      type: Array,
      required: true,
    },
    initialVisibleAssets: {
      type: Number,
      default: 6,
    },
    filterOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: this.filterOpen,
      isExpanded: false,
    };
  },

  computed: {
    showMoreFiltersButton() {
      return this.filters.length > this.initialVisibleAssets;
    },
    moreFiltersNumber() {
      return this.filters.length - this.initialVisibleAssets;
    },
  },

  watch: {
    filterOpen(newValue) {
      if (newValue === true) {
        this.isOpen = newValue;
      }
    },
  },

  methods: {
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
    },
    select(filter) {
      this.$emit("selectedFilter", filter);
    },
    isSelected(filter) {
      return this.selectedFilters.includes(filter.uuid);
    },
  },
};
</script>

<style scoped>
input[type="checkbox"]:checked + label > div:first-child {
  @apply border-primary-50 bg-primary-50;

  & + div {
    @apply text-neutral-900;
  }

  & > svg {
    @apply text-primary-600 opacity-100;
  }
}
</style>
