<template>
  <div class="sm:container">
    <div v-if="error">
      {{ error }}
    </div>
    <div
      v-else
      class="grid grid-cols-1 gap-4 sm:mx-auto md:grid-cols-12 md:gap-8"
    >
      <form
        ref="filterForm"
        class="px-5 md:col-start-1 md:col-end-5 md:px-0 xl:col-end-4"
      >
        <FilterFrame
          :results="assets.length"
          :results-text="
            this.$i18n.tc(`pages.brandAllAssets.filterResult`, assets.length, {
              amount: assets.length,
            })
          "
        >
          <div class="flex justify-end px-5 py-3 md:mb-3 md:p-0">
            <button
              type="reset"
              class="focus:outline-none group inline-flex items-center"
              @click="resetFilters"
            >
              <IconReset
                class="h-5 w-5 flex-shrink-0 text-neutral-400 transition-all duration-200 group-hover:text-neutral-600"
              />
              <span class="p-0 text-2xs font-bold uppercase text-neutral-800">
                {{ $t("pages.brandAllAssets.resetFilter") }}
              </span>
            </button>
          </div>
          <div
            class="border relative rounded-sm border-neutral-50 bg-white pb-16 md:pb-0"
          >
            <div>
              <AssetsFilter
                :title="this.$i18n.tc(`pages.brandAllAssets.categoriesTitle`)"
                :loading="categoriesLoading"
                :filter-open="true"
                :filters="categories"
                :selected-filters="categoriesFilter"
                @selectedFilter="updateCategoriesFilter"
              />
              <AssetsFilter
                :title="this.$i18n.tc(`pages.brandAllAssets.tagsTitle`)"
                :loading="tagsLoading"
                :filters="tags"
                :selected-filters="tagsFilter"
                :filter-open="tagsFilterOpen"
                @selectedFilter="updateTagsFilter"
              />
              <AssetsFilter
                :title="this.$i18n.tc(`pages.brandAllAssets.fileTypesTitle`)"
                :loading="fileTypesLoading"
                :filters="fileTypes"
                :selected-filters="fileTypesFilter"
                :filter-open="fileTypesFilterOpen"
                @selectedFilter="updateFileTypesFilter"
              />
            </div>
          </div>
        </FilterFrame>
      </form>

      <div class="md:col-start-5 md:col-end-13 xl:col-start-4">
        <div v-show="assetsLoading">
          <LoadingIndicator class="mx-auto mt-8 w-8" />
        </div>

        <div v-show="!assetsLoading">
          <div class="mb-3 hidden text-xs text-neutral-500 md:block">
            {{
              $tc("pages.brandAllAssets.result", assets.length, {
                amount: assets.length,
              })
            }}
          </div>

          <AssetsRow
            v-for="asset in assets"
            :key="asset.uuid"
            :asset="asset"
            :can-select="true"
            :is-asset-selected="isAssetSelected"
            :toggle-select-asset="toggleSelectAsset"
            @open="openModalDetails"
          />

          <EmptyState v-if="assets.length === 0" @resetFilters="resetFilters" />
        </div>
      </div>
    </div>

    <transition name="fade">
      <AssetsModalDetails
        v-if="modalDetailsOpen"
        :asset="focusedAsset"
        :categories="categories"
        :tags="tags"
        @close="closeModal"
        @clickedTag="addOrKeepTagFilter"
        @resetFilters="resetFilters"
      />
    </transition>
  </div>
</template>

<script>
import AssetsRow from "@/components/brand-marketing/assets/AssetsRow";
import AssetsFilter from "@/components/brand-marketing/assets/AssetsFilter";
import EmptyState from "@/components/brand-marketing/EmptyState";

import AssetsModalDetails from "@/components/brand-marketing/assets/AssetsModalDetails";
import FilterFrame from "@/components/brand-marketing/FilterFrame";

import FIND_ASSETS from "@/graphql/FindAssets.gql";
import FIND_ASSETS_CATEGORIES from "@/graphql/FindAssetsCategories.gql";
import FIND_ASSETS_TAGS from "@/graphql/FindAssetsTags.gql";
import FIND_ALLOWED_FILE_TYPES from "@/graphql/FindAllowedAssetFileTypes.gql";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "BrandAllAssets",

  components: {
    AssetsRow,
    AssetsFilter,
    AssetsModalDetails,
    EmptyState,
    FilterFrame,
  },

  props: {
    initialTagsFilter: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      error: null,
      mobileFilterOpen: false,

      modalDetailsOpen: false,
      modalUploadOpen: false,

      focusedAssetUuid: undefined,

      assets: [],

      categories: [],
      tags: [],
      fileTypes: [],

      categoriesFilter: [],
      tagsFilter: [],
      fileTypesFilter: [],

      selectedFile: null,
    };
  },

  computed: {
    ...mapGetters("packageBuilder", ["isAssetSelected"]),

    assetsLoading() {
      return this.$apollo.queries.assets.loading;
    },
    categoriesLoading() {
      return this.$apollo.queries.categories.loading;
    },
    tagsLoading() {
      return this.$apollo.queries.tags.loading;
    },
    fileTypesLoading() {
      return this.$apollo.queries.fileTypes.loading;
    },
    focusedAsset() {
      return this.assets.find((asset) => asset.uuid === this.focusedAssetUuid);
    },
    tagsFilterOpen() {
      return this.tagsFilter.length > 0;
    },
    fileTypesFilterOpen() {
      return this.fileTypesFilter.length > 0;
    },
  },

  apollo: {
    assets: {
      query: FIND_ASSETS,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
      variables() {
        return {
          categories: this.categoriesFilter,
          tags: this.tagsFilter,
          fileTypes: this.fileTypesFilter,
        };
      },
    },
    categories: {
      query: FIND_ASSETS_CATEGORIES,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
    tags: {
      query: FIND_ASSETS_TAGS,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
    fileTypes: {
      query: FIND_ALLOWED_FILE_TYPES,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
  },

  created: function () {
    this.tagsFilter = this.initialTagsFilter;
    this.$parent.$on("emitResetFilters", this.resetFilters);
  },

  methods: {
    ...mapActions("packageBuilder", ["toggleSelectAsset"]),

    openModalDetails(asset) {
      this.focusedAssetUuid = asset.uuid;
      this.modalDetailsOpen = true;
      document.body.classList.add("overflow-y-hidden");
    },
    openModalUpload(files) {
      this.selectedFile = files[0];
      this.modalUploadOpen = true;
      document.body.classList.add("overflow-y-hidden");
    },
    closeModal() {
      this.modalDetailsOpen = false;
      this.modalUploadOpen = false;
      this.selectedFile = null;
      document.body.classList.remove("overflow-y-hidden");
    },

    toggleMobileFilter() {
      this.mobileFilterOpen = !this.mobileFilterOpen;
    },
    resetFilters() {
      this.categoriesFilter = [];
      this.tagsFilter = [];
      this.fileTypesFilter = [];
      this.$refs.filterForm.reset();
    },
    updateFilter(filter, uuid) {
      //@TODO Unit test this method
      if (filter.includes(uuid)) {
        filter.splice(filter.indexOf(uuid), 1);
      } else {
        filter.push(uuid);
      }
    },
    updateCategoriesFilter(filter) {
      this.updateFilter(this.categoriesFilter, filter.uuid);
    },
    updateTagsFilter(filter) {
      this.updateFilter(this.tagsFilter, filter.uuid);
    },
    updateFileTypesFilter(filter) {
      this.updateFilter(this.fileTypesFilter, filter.uuid);
    },
    addOrKeepTagFilter(tag) {
      if (!this.tagsFilter.includes(tag.uuid)) {
        this.tagsFilter.push(tag.uuid);
      }
    },
  },
};
</script>
