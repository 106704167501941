var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-t border-neutral-50 first:border-transparent"},[_c('button',{staticClass:"focus:outline-none group flex w-full items-center justify-between p-5 pr-4",attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleOpen()}}},[_c('div',{staticClass:"flex items-baseline"},[_c('span',{staticClass:"mr-2 text-sm font-medium text-neutral-900"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.selectedFilters.length > 0)?_c('span',{staticClass:"text-xs text-neutral-700"},[_vm._v(" "+_vm._s(_vm.$t("components.brandMarketing.assets.assetsFilter.numberSelected", { number: _vm.selectedFilters.length, }))+" ")]):_vm._e()]),_c('div',{staticClass:"relative h-6 w-6 text-neutral-400 transition-all duration-200 group-hover:text-neutral-900"},[_c('IconMinus',{staticClass:"absolute inset-0"}),_c('IconMinus',{class:[
          'absolute inset-0 transform transition-transform duration-200',
          { 'rotate-90': !_vm.isOpen } ]})],1)]),_c('div',{class:[
      'px-5 pb-5',
      {
        hidden: !_vm.isOpen,
      } ]},[(_vm.loading)?_c('div',[_c('LoadingIndicator',{staticClass:"mx-auto w-6"})],1):_vm._e(),(!_vm.loading)?_c('div',[_vm._l((_vm.filters),function(filter,index){return _c('div',{key:index,class:[
          'relative mb-4',
          {
            hidden: index >= _vm.initialVisibleAssets && !_vm.isExpanded,
            'last:mb-0': !_vm.showMoreFiltersButton,
          } ]},[_c('input',{staticClass:"absolute top-0 left-0 opacity-0",attrs:{"type":"checkbox"},domProps:{"checked":_vm.isSelected(filter)}}),_c('label',{staticClass:"group relative flex cursor-pointer items-center text-sm text-neutral-600",on:{"click":function($event){return _vm.select(filter)}}},[_c('div',{staticClass:"mr-2 flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-sm border-2 border-neutral-100 bg-white transition-all duration-200 group-hover:border-neutral-300"},[_c('IconCheckbox',{staticClass:"opacity-0"})],1),_c('div',[_vm._v(_vm._s(filter.name))])])])}),(_vm.showMoreFiltersButton)?_c('button',{staticClass:"focus:outline-none group mt-2 inline-flex items-center text-xs font-medium",attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleExpanded()}}},[_c('div',{staticClass:"mr-2 flex h-5 w-5 flex-shrink-0 items-center justify-center"},[_c('IconChevronDown',{staticClass:"h-4 w-4 transform text-neutral-400 transition-all duration-200 group-hover:text-neutral-900",class:{ 'rotate-180': _vm.isExpanded }})],1),(!_vm.isExpanded)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("components.brandMarketing.assets.assetsFilter.button", { number: _vm.moreFiltersNumber, label: _vm.title.toLowerCase(), }))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("components.brandMarketing.assets.assetsFilter.showLess"))+" ")])]):_vm._e()],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }